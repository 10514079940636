<template>
  <b-card class="app-calendar p-2">
    <FullCalendar :options="calendarOptions" class="full-calendar">
      <template v-slot:eventContent="arg">
        <template v-if="arg?.event?.extendedProps?.holidays">
          <div
            role="button"
            class="d-flex flex-column justify-content-center align-items-center text-white w-100 h-100 p-1"
          >
            <span class="text-wrap mb-1">
              <b>{{ arg?.event?.extendedProps?.holidays?.title }}</b>
            </span>

            <span class="text-wrap text-truncate">
              {{ arg?.event?.extendedProps?.holidays?.description }}
            </span>
          </div>
        </template>
      </template>
    </FullCalendar>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormDatepicker,
  BFormTextarea,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Ripple from "vue-ripple-directive";

export default {
  name: "HolidaysCalenderView",
  components: {
    BCard,
    FullCalendar,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormDatepicker,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      calendarOptions: {},
    };
  },
  async created() {
    this.calendarOptions = {
      plugins: [multiMonthPlugin, dayGridPlugin, interactionPlugin],
      headerToolbar: {
        start: "prev,next, title",
        end: "multiMonthYear,dayGridMonth",
      },
      initialView: "multiMonthYear",
      weekends: true,
      multiMonthMaxColumns: 1,

      datesSet: async (event) => {
        const getHolidaysByYear = await this.getHolidaysByYear({
          year: new Date(event?.start).getFullYear(),
        });

        // getHolidaysByYear
        const mapHolidays = (getHolidaysByYear?.data?.data || []).map(function (
          item
        ) {
          return {
            start: item?.date,
            end: item?.date,
            allDay: true,
            display: "background",
            borderColor: "#ea5455",
            backgroundColor: "#ea5455",
            extendedProps: {
              holidays: {
                ...item,
              },
            },
          };
        });

        this.calendarOptions.events.push(...mapHolidays);
      },
      events: [],
      showNonCurrentDates: false,
    };
  },
  methods: {
    async getHolidaysByYear(params) {
      try {
        return await this.$api.get("api/holidays/get-by-year", {
          params: {
            year: params.year,
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
